<template>
  <split-pane  :min-percent='10' :default-percent='12' split="vertical">
        <template slot="paneL">        
            <el-tree :data="treeData" 
                    :props="defaultProps" 
                    @node-click="handleNodeClick" 
                    node-key="id"   
                    :default-expanded-keys="['156']"                     
                    :style="{height: scrollerHeight,overflow:'auto' }"
                    highlight-current
                    :filter-node-method="filterNode"
                    ref="eltree"
                    :expand-on-click-node="false">
                <template v-slot="{node,data}">                                     
                    <span v-if="data.id == 158 " class="el-icon-folder"  >
                        <span class="nodeLabel">{{ node.label}}【{{dataCount158}}】</span>
                    </span>  
                    <span v-else-if="data.id == 159 " class="el-icon-folder"  >
                        <span class="nodeLabel">{{ node.label}}【{{dataCount159}}】</span>
                    </span> 
                    <span v-else-if="data.id == 160 " class="el-icon-folder"  >
                        <span class="nodeLabel">{{ node.label}}【{{dataCount160}}】</span>
                    </span> 
                    <span v-else-if="data.id == 19596 " class="el-icon-folder"  >
                        <span class="nodeLabel">{{ node.label}}【{{dataCount161}}】</span>
                    </span> 
                    <span v-else class="el-icon-folder" > 
                        <span class="nodeLabel">{{ node.label}}  </span>
                    </span>
                </template>
            </el-tree>        
        </template>
      <template slot="paneR"> 
          <div class="siderCls" >
            <el-button type="text" size="mini" icon="iconfont icon-chenggong1" @click="selSure" style="color:#409eff; height:35px;margin-top:3px;margin-right:20px;">确定选择</el-button> 
            <span style="margin-right:6px;font-size:12px;"></span>
            <el-radio-group v-model="stfgSet"   size="small" @change="stfgChange">
                <el-radio border label="ALL"  >所有</el-radio>
                <el-radio border label="N" >待审核</el-radio>
                <el-radio border label="Y" >已审核</el-radio>
            </el-radio-group>
            <span style=" margin-left:20px;font-size:12px;">字段查询</span>        
            <el-input :placeholder="input_hold" v-model="textparam" @keyup.enter.native="queryData" size="small" style="width:350px;margin-left:10px;vertical-align:center">
              <el-select v-model="selparam" slot="prepend" placeholder="请选择列名" clearable @change="elchange" style="width:140px;">
                  <span v-for="item in cltCol" :key='item.key'>
                      <el-option :label="item.title" :value="item.key"></el-option>
                  </span>
              </el-select>
            </el-input> 
          <el-table  :data="cltData" 
              border                            
              size="small"
              ref="custab"
              v-loading="loading"
              element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 251, 255, 0.8)"
              :height="subHeight" 
              highlight-current-row
              @row-dblclick="selSure"
              style="overflow:auto;margin-top:1px;"
              @row-click="matClick" > 
              <span v-for="(item,index ) in cltCol" :key="index" >
                <el-table-column  
                    v-if="item.thide==='N'"
                    :prop="item.key"
                    :fixed="item.key==='RN'?true:false"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    sortable
                    :min-width="item.width">
                </el-table-column> 
              </span>
                   
          </el-table>
          <div style="margin-top: 2px;margin-bottom:2px;overflow: hidden">
                <div style="float: left;">
                    <Page :total="dataCount" :page-size="pageSize" show-total :current="1" @on-change="changePage"></Page>
                </div>
          </div> 
        </div>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
            </div>
            <div style=" margin:15px 10px">
                {{prompt}}
            </div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
      </template>
  </split-pane>
</template>
<script>
    import { getTabColOrData } from '../../api/user';
    import { filterArrObj } from '../../api/Tools';
    import {  paramBase, getDate,baseCascade,paramData} from '../../api/Select'
    export default {
    data() {
        return {
            dataCount158:0,
            dataCount159:0,
            dataCount160:0,
            dataCount161:0,
            addtype:'',
            picDialog:false,
            selectimageTy:'',
            promptWin:false,
            loading:false,
            dataCount:0,
            stfgSet:'ALL',
            prompt:'',
            videoSrc:'',
            isShow:true,
            type:'create',
            multiprop:{ multiple: true },
            cascadeKey:1,
            specFlag:'create',
            paramTree:'',
            imgSrc:'',
            LANG:'',
 
            treeData:[] ,//  树数据
            cltCol:[], //客户列（表格隐藏列）

            matRow:{}, //产品行
 
            imgType:'',  // 
 
            selparam:'IDSEQ',// select 绑定值
            textparam:'', // input 绑定值
 
            defaultProps: 
            {  //el-tree 默认字段属性
                children: 'children',
                id:'id',
                icon:'icon',
                label: 'label'
            },
            saveFlag:true,
            nodeId:'235',
            cltData:[] , // customer数据
            v_url:this.$store.state.queryUrl , //api请求路径     
            v_many:this.$store.state.manyUrl , //api请求路径   
   
            v_username:this.$cookies.get('v_username'), //api 请求用户
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            input_hold:'请输入内容...',
 
            allData:[] , //保存产品所有数据
            pageSize:25,
            pageNum:1,
        }
    },
    watch: {
    },
    components: {
 
    },
    computed: {
        scrollerHeight: function() {
            return (window.innerHeight - 200) + 'px';
        }, 
        subHeight: function() {
            return (window.innerHeight - 230) + 'px';
        }, 
    },
    mounted () {       
 
    },
    created () {  
      this.qryClntDataCount('stfg','%')
      this.getData()     
      this.getTabCol() //产品表列字段
      
    },
    methods: { 
        selSure(){
            if (this.matRow['CLNTNM']){
                this.$emit('child-clnt',this.matRow['IDSEQ'],this.matRow['CLNTNM'])
            }else{
                this.promptWin=true
                this.prompt='请选择一客户记录'
            }
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.pageSize;
            var _end = index * this.pageSize;
            this.cltData = this.allData.slice(_start,_end);
        },  
 
        //状态查询
        stfgChange(){
            if (this.stfgSet==='ALL'){
                this.qryClntData(this.nodeId,'stfg','%')  
            }else{
                this.qryClntData(this.nodeId,'stfg',this.stfgSet)  
            }
        },  
        queryData(){
            if (this.nodeId)
            this.qryClntData(this.nodeId,this.selparam,this.textparam)
        },
 
        toggleLeft(){
            this.isShow =!this.isShow
        },
 
        //查询数据
        qryClntDataCount( key,value){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_bs_clnt','','','','','','',' and instr(cltype,'+158+')>0 and '+key+' like \'%'+value+'%\'').then((res) => {
                this.dataCount158 =res.data.length //成交客户
            })
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_bs_clnt','','','','','','',' and instr(cltype,'+159+')>0 and '+key+' like \'%'+value+'%\'').then((res) => {
                this.dataCount159 =res.data.length //潜在客户
            })
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_bs_clnt','','','','','','',' and instr(cltype,'+160+')>0 and '+key+' like \'%'+value+'%\'').then((res) => {
                this.dataCount160 =res.data.length //不信任客户
            })
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_bs_clnt','','','','','','',' and instr(cltype,'+19596+')>0 and '+key+' like \'%'+value+'%\'').then((res) => {
                this.dataCount161 =res.data.length //线索客户
            })
        },
        //查询supply信息
        qryClntData(nodeId,key,value){
            let vm=this
            vm.loading=true
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_bs_clnt','','','','','','',' and instr(cltype,'+nodeId+')>0 and '+key+' like \'%'+value+'%\'').then((res) => {
                //保存取到 所有数据
                vm.allData =res.data
                vm.dataCount =res.data.length //总条数
                // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
                if (res.data.length < vm.pageSize){
                    vm.cltData = vm.allData
                }else{
                    vm.cltData =vm.allData.slice(0,vm.pageSize) //返回选定元素的子数组，不包含结尾元素
                }
                vm.loading=false
                //希望在 DOM 元素中某些属性发生变化之后重新应用该插件
                this.$nextTick(() => { //有固定列时 查询后重新布局
                    this.$refs.custab.doLayout();
                }) 
            })
        },
 
        // 查询界面单 行事件
        matClick(row){
            this.matRow =row
        },
        elchange(val){  // 这个val 就是 el-select 的 value
            let obj ={}
            obj =this.cltCol.find(item=>{
                return item.key===val
            })
            if (obj)
            this.input_hold='请输入'+obj.title+' 的值'
        },
        //获取表列头字段
        getTabCol(){
            var vm=this
            getTabColOrData(vm.v_many,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','67','fty','HEAD','','',' order by sortby,lstseq').then((res) => {
                if (res.data.code==='200'){
                    for (let k=0; k<res.data.result.length; k++){
                        vm.cltCol.push({ //向数组的开头 添加序列号
                            title: res.data.result[k].title,
                            key: res.data.result[k].key,
                            align: res.data.result[k].align,
                            width: res.data.result[k].width,
                            thide: res.data.result[k].THIDE,
                            fhide: res.data.result[k].FHIDE,
                            sortable: res.data.result[k].sortable?true:false,
                        })
                    }
                }else{
                   this.$Message.info('系统断开连接，请重新登录') 
                }
            });
 
        },
        handleNodeClick(data){
            console.log(data.id)
            this.nodeId =data.id
            if (data.id)
            this.qryClntData(data.id,this.selparam,this.textparam)
        }  ,
        //节点过滤
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        },
        // 获取 树形控件数据
        getData() {  
            var vm=this;
            this.$axios({
                method: 'post',
                url:vm.$store.state.baseUrl+'sysprivs/getElTreeData?username='+vm.$cookies.get('v_username')+'&password='+vm.$cookies.get('v_password'),
                data: {p_table:'V_CLT_TREE'}
            }).then(function(res){                  
                vm.treeData =res.data;   
                if(res.data.length > 0 ){
                    //'nextTick()' 下次dom更新时触发回调函数
                    vm.$nextTick().then(() =>{
                        //使用setCurrentKey方法的时候必须将其放在nextTick中,要等数据更新渲染完再调用,否则找不到对应元素
                        vm.$refs.eltree.setCurrentKey(vm.nodeId)
                    })
                }     
            }).catch(function(err){})   
                                      
        },
    },
 }
</script>

<style scoped>
    .el-cascader-menu{
        height:400px;
        width:100%;
    }
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
    .row-width  {
        width: 97%;
        color:#409EFF;
    }
</style> 
<style lang="less">
    .md-menu{margin-right: 15px;cursor: pointer}
    .md-menu:hover{background-color:#dae9f7 }

    .row-width  {
        width: 98.5%;
    }
    .el-card__header{
        //height:35px;
        vertical-align: middle;
    }
    .el-table {
        th {
            padding: 0 ;
        }
        thead{
            height: 15px;
            color:rgb(11, 11, 59);
            font-size:12px;
            background:rebeccapurple;
        }
        td {
            padding: 5px ;
            font-size:11px;
        } 
    }
 
</style>
